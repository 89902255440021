// import { useRef } from "react";
// import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar.css";
import { Navbar, Nav} from 'react-bootstrap';  

function NavigationBar() {
	return (
	  <Navbar  bg="white" expand="lg" style={{ marginBottom: "2px"}}>
		<Navbar.Brand href="#">Kenneth Situ</Navbar.Brand>
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
		<Navbar.Collapse id="basic-navbar-nav">
		<Nav className="mx-auto" style={{ justifyContent: "center" }}>
  		<Nav.Link href="#about">About</Nav.Link>
  		<Nav.Link href="#projects">Projects</Nav.Link>
  		<Nav.Link href="#contact">Contact</Nav.Link>
		</Nav>
		</Navbar.Collapse>
	  </Navbar>
	);
  }

export default NavigationBar;