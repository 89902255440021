import React from 'react';
import portfolioImage from '../asset/image/20221115_132215.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './portfolioImage.css';

const PortfolioImage = () => {
    return (
    <div className="Image">
        <Container>
            <Row>
                <Col sm={12} md={6} className="d-flex align-items-center">
                    <img src={portfolioImage} alt="Portrait" className="img-fluid" style={{ objectPosition: 'center top' }}/>
                </Col>
                <Col sm={12} md={6} className="text-center d-flex flex-column justify-content-center">
                    {/* <div style={{ fontSize: "12px" }}>
                            <h2>About Me</h2>
                            <p>I'm a Cloud Platform Engineer from Vancouver, BC with over 5 years of experience in IT. I have a Diploma in Computer Science and several certifications in cloud computing and virtualization. In my current role, I work with various cloud platforms such as AWS, and Azure to design, deploy, and manage cloud infrastructure. I'm also responsible for troubleshooting issues, optimizing performance, and ensuring security and compliance.</p>
                            <p>In my free time, I enjoy coding personal projects using various programming languages such as Python, JavaScript, and Java. I find it both challenging and rewarding to build software solutions that solve real-world problems or automate mundane tasks. One of my core values is reliability, which I believe is essential in the IT industry. I strive to deliver high-quality solutions and services that meet or exceed my clients' expectations. I also believe in collaboration and teamwork, which has been crucial in my successful project deliveries.</p>
                            <p>In the future, I hope to continue growing my skills and knowledge in cloud computing and IT, and perhaps even become a certified cloud architect. I'm excited about the opportunities and challenges that lie ahead and am committed to staying on top of my game to achieve my goals.</p>
                    </div> */}
                    <Row>
                        <h16>HI THERE!</h16>
                        <h1 className="font-weight-bold">I am Kenneth Situ</h1>
                        <h5>Cloud Platform Engineer | Developer</h5>  
                    </Row>  

                    <Row className="justify-content-center">
                        <Col xs={2} md={1}>
                            <a href="https://www.linkedin.com/in/kenneth-situ-8325a7106/">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                            </a>
                        </Col>
                        <Col xs={2} md={1}>
                            <a href="https://github.com/KennyMango">
                            <FontAwesomeIcon icon={faGithub} size="2x" />
                            </a>
                        </Col>
                        <Col xs={2} md={1}>
                            <a href="https://www.facebook.com/ksitu">
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                        </Col>
                    </Row>


                </Col>
            </Row>
            <Row>
                <Col md={12} id="about-me" style={{ padding: "60px"}}>
                    <div style={{ fontSize: "12px" }}>
                        <h2>About Me</h2>
                        <p>I'm a Cloud Platform Engineer from Vancouver, BC with over 5 years of experience in IT. I have a Diploma in Computer Science and several certifications in cloud computing and virtualization. In my current role, I work with various cloud platforms such as AWS, and Azure to design, deploy, and manage cloud infrastructure. I'm also responsible for troubleshooting issues, optimizing performance, and ensuring security and compliance.</p>
                        <p>In my free time, I enjoy coding personal projects using various programming languages such as Python, JavaScript, and Java. I find it both challenging and rewarding to build software solutions that solve real-world problems or automate mundane tasks. One of my core values is reliability, which I believe is essential in the IT industry. I strive to deliver high-quality solutions and services that meet or exceed my clients' expectations. I also believe in collaboration and teamwork, which has been crucial in my successful project deliveries.</p>
                        <p>In the future, I hope to continue growing my skills and knowledge in cloud computing and IT, and perhaps even become a certified cloud architect. I'm excited about the opportunities and challenges that lie ahead and am committed to staying on top of my game to achieve my goals.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    
    // <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
    //     <Row>
    //         <Col md={4} style={{ padding: "0px"}}>
    //             <Image src={portfolioImage} style={{ height: "660px", width: "500px"}} />
    //         </Col>
    //         <Col md={8} id="about-me" style={{ padding: "60px"}}>
    //             <div style={{ fontSize: "12px" }}>
    //                 <h2>About Me</h2>
    //                 <p>I'm a Cloud Platform Engineer from Vancouver, BC with over 5 years of experience in IT. I have a Diploma in Computer Science and several certifications in cloud computing and virtualization. In my current role, I work with various cloud platforms such as AWS, and Azure to design, deploy, and manage cloud infrastructure. I'm also responsible for troubleshooting issues, optimizing performance, and ensuring security and compliance.</p>
    //                 <p>In my free time, I enjoy coding personal projects using various programming languages such as Python, JavaScript, and Java. I find it both challenging and rewarding to build software solutions that solve real-world problems or automate mundane tasks. One of my core values is reliability, which I believe is essential in the IT industry. I strive to deliver high-quality solutions and services that meet or exceed my clients' expectations. I also believe in collaboration and teamwork, which has been crucial in my successful project deliveries.</p>
    //                 <p>In the future, I hope to continue growing my skills and knowledge in cloud computing and IT, and perhaps even become a certified cloud architect. I'm excited about the opportunities and challenges that lie ahead and am committed to staying on top of my game to achieve my goals.</p>
    //             </div>
    //         </Col>
    //     </Row>
    // </Container>

    );
  }

export default PortfolioImage;