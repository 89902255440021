import './home.css';
import NavigationBar from '../../components/navbar/NavigationBar';
import PortfolioImage from '../../components/portfolioImage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';


function home() {
  return (
    <div className="home">
      <Container>
      <NavigationBar/>
      <Row>
      <PortfolioImage />
      </Row>
      </Container>
      
    </div>
  );
}

export default home;
